<template>
  <el-dialog
    :title="$t('chargeGroup.createChargeGroup')"
    v-model="isShow"
    width="700px"
    :before-close="handleClose"
  >
    <el-form
      ref="chargeGroupForm"
      :inline="true"
      :model="chargeGroupInfo"
      size="mini"
      :rules="chargeGroupRules"
    >
      <div class="row">
        <el-form-item
          :label="$t('chargeGroup.code')"
          prop="code"
          label-width="120px"
        >
          <el-input
            v-model.trim="chargeGroupInfo.code"
            :placeholder="$t('system.pleaseInput') + $t('chargeGroup.code')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('chargeGroup.name')"
          prop="name"
          label-width="120px"
        >
          <el-input
            v-model.trim="chargeGroupInfo.name"
            :placeholder="$t('system.pleaseInput') + $t('chargeGroup.name')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>

      <div class="row">
        <el-form-item
          :label="$t('chargeGroup.organization')"
          prop="orgId"
          label-width="120px"
        >
          <el-select
            filterable
            v-model="chargeGroupInfo.orgId"
            :placeholder="$t('system.pleaseSelect') + $t('chargeGroup.organization')"
            clearable
          >
            <el-option
              :label="item.companyNameCn"
              :value="'' + item.id"
              v-for="(item, index) in organizationList"
              :key="index"
            >
              <span style="float: left">{{ item.companyNameCn }}</span>
              <span
                style="
                  float: right;
                  color: 'var(--el-text-color-secondary)';
                  font-size: 12px;
                "
                >{{ item.companyCode }}</span
              ></el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('chargeGroup.remark')"
          prop="remark"
          label-width="120px"
        >
          <el-input
            v-model.trim="chargeGroupInfo.remark"
            :placeholder="$t('system.pleaseInput') + $t('chargeGroup.remark')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" size="mini">{{
          $t("system.cancel")
        }}</el-button>
        <el-button type="primary" @click="doCreateChargeGroup" size="mini">{{
          $t("system.confirm")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import baseServer from "../source/basedata";
export default {
  props: ["isShowDialog"],
  emits: ["onDialogClose"],
  data() {
    return {
      chargeGroupInfo: {
        code: null,
        name: null,

        orgId: null,
        remark: null,
      },
      chargeGroupRules: {
        name: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("chargeGroup.name"),
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("chargeGroup.code"),
            trigger: "blur",
          },
        ],

        orgId: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("chargeGroup.organization"),
            trigger: "blur",
          },
        ],
      },
      // organizationList: [
      //   { id: 1, name: "壹站供应链管理有限公司" },
      //   { id: 2, name: "上海道骊物流有限公司" },
      // ],
    };
  },
  computed: {
        organizationList(){
      return this.$store.state.constants.saleCompanys;
    },
    isShow() {
      return this.isShowDialog;
    },
  },
  methods: {
    handleClose() {
      this.$emit("onDialogClose");
    },
    doCreateChargeGroup() {
      this.$refs["chargeGroupForm"].validate((valid) => {
        if (valid) {
          baseServer.addChargeGroup(this.chargeGroupInfo).then(() => {
            this.$confirm(
              this.$t("chargeGroup.createSuccess"),
              this.$t("system.continue") + "?",
              {
                confirmButtonText: this.$t("system.confirm"),
                cancelButtonText: this.$t("system.cancel"),
                type: "success",
              }
            )
              .then(() => {
                this.$refs["chargeForm"].resetFields();
              })
              .catch(() => {
                this.handleClose();
              });
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.el-form-item__label) {
    font-size: 12px;
  }
  :deep(.el-input--mini) {
    max-width: 180px;
  }
}
</style>
